import React from "react"
import Layout from "../components/layout"
import HeaderOne from "../components/header-one"
import BannerOne from "../components/banner-one"
import Services from "../components/services"
import FeatureOne from "../components/feature-one"
import FeatureTwo from "../components/feature-two"
import Pricing from "../components/pricing"
import Faq from "../components/faq"
import Footer from "../components/footer"
import Seo from "../components/seo"

const Home = () => {
  return (
    <Layout pageTitle="ResumeThinker - The next HR experience with AI">
      <Seo title="ResumeThinker" />
      <HeaderOne />
      <BannerOne />
      <Services />
      <FeatureTwo />
      <FeatureOne />
      <Pricing />
      <Faq />
      <Footer />
    </Layout>
  )
}

export default Home
