import React, { useState, useEffect, useRef } from "react"
import { Link } from "gatsby"
import NavLinks from "./nav-links"
import logoImage from "../assets/images/logo.svg"
import logoText from "../assets/images/ResumeThinker.png"

const HeaderOne = () => {
  const [sticky, setSticky] = useState(false)
  const menuEl = useRef(null)
  useEffect(() => {
    window.addEventListener("scroll", handleScroll)
    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  const handleScroll = () => {
    if (window.scrollY > 70) {
      setSticky(true)
    } else if (window.scrollY < 70) {
      setSticky(false)
    }
  }

  const openCalendy = () => {
    window.open("https://www.calendly.com/julio-almeida/product-showoff", "_blank")
  }

  const getWith = () => {
    if (typeof window !== "undefined") {
      return window.innerWidth
    }
  }

  const buttonVisible = () => {
    if (getWith() < 1000) {
      return "hidden"
    } else {
      return "visible"
    }
  }

  return (
    <header className="site-header header-one">
      <nav
        className={`navbar navbar-expand-lg navbar-light header-navigation stricky  ${
          sticky === true ? "stricky-fixed" : " "
        }`}
      >
        <div className="container clearfix">
          <div className="logo-box clearfix">
            <img src={logoImage} alt="Awesome alter logo" className=""/>
            <img src={logoText} alt="Awesome alter Text" style={{marginLeft: 10}}/>
            {
              getWith() > 1000 &&
              <button
                className="menu-toggler"
                style={{visibility: buttonVisible()}}
                onClick={e => {
                  e.preventDefault()
                  menuEl.current.classList.toggle("active")
                }}
              >
                <span className="fa fa-bars"></span>
              </button>
            }
          </div>
          {
            getWith() > 1000 &&
            <>
              <div className="main-navigation" ref={menuEl} style={{visibility: buttonVisible()}}>
                <NavLinks />
              </div>
              <div className="right-side-box" style={{visibility: buttonVisible()}}>
                <a href="#none" className="header-btn" onClick={openCalendy} style={{visibility: buttonVisible()}}>
                  <i className="fa fa-phone" style={{marginRight: 10, visibility: buttonVisible()}} ></i>
                  Want a Demo?
                </a>
              </div>
            </>
          }
        </div>
      </nav>
    </header>
  )
}

export default HeaderOne
