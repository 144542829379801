import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import moc from "../assets/images/mainImage.png"

const BannerOne = () => {

  const openCalendy = () => {
    window.open("https://www.calendly.com/julio-almeida/product-showoff", "_blank")
  }

  return (
    <section className="banner-style-one" id="banner">
      <span className="bubble-1"></span>
      <span className="bubble-2"></span>
      <span className="bubble-3"></span>
      <span className="bubble-4"></span>
      <span className="bubble-5"></span>
      <span className="bubble-6"></span>
      <Container>
        <Row>
          <Col xl={6} lg={12} md={12} sm={12} xs={12}>
            <div className="content-block">
              <h3>
                Every resume in your <br /> hands with the next <br /> Gen AI Tools.
              </h3>
              <p style={{marginTop: -30}}>
                <br />
                Convert, extract, ask...everything at your fingertips 
                <br />
              </p>
              <div className="button-block">
                <a href="#none" className="banner-btn" onClick={openCalendy}>
                  <i className="fa fa-phone"></i>
                  Want a Demo?
                </a>
                {/* <a href="#none" className="banner-btn">
                  <i className="fa fa-apple"></i>
                  Get in<span>Azure</span>
                </a> */}
              </div>
            </div>
          </Col>
          <Col xl={6} lg={12} md={12} sm={12} xs={12}>
            <img src={moc} className="banner-mock" alt="Awesome alter Text" />
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default BannerOne
